.App{
  padding-top: 50px;

}

.Content{
  margin-left: 10px;
}

.Button{
  background-color: gray;
  margin: 5px;

}

.Button:hover{
  background-color: darkgray;
}

.share:hover{
  color: #e55d5d;
}


.Editor{
  font-size: var(--mantine-font-size-xl);
}

.header{
  position: sticky;
  top: 00px;
  height: 160px;
  background-color: white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.header.hide {
  top: -160px;
}

.disciplineLink:link {
  text-decoration : none;
}

.disciplineLink:visited{
  text-decoration: none;
}

.disciplineLink:hover{
  text-decoration: none;
  cursor: pointer;
}

.disciplineLink:active{
  text-decoration: none;
}


